<template>
  <div class="container">
    <h1>Просмотр ответа</h1>

    <div v-if="offer">
      <div class="order-item">
        <div class="row">
          <div class="col-auto" v-if="offer.order['vehicle']['photo']">
            <div class="order-item__image">
              <b-link :to="'/orders/' + offer.order._id + '/vehicle'">
                <img
                  :src="
                    'https://vp.bw2api.ru/' +
                    offer.order['vehicle']['photo']['url']
                  "
                  class="img-fluid"
                />
              </b-link>
            </div>
          </div>
          <div class="col">
            <b-link
              :to="'/orders/' + offer.order._id + '/vehicle'"
              class="order-item__car-name"
            >
              {{ offer.order["vehicle"]["brand"] }}
              {{ offer.order["vehicle"]["model"] }}
              {{ offer.order["vehicle"]["year"] }}
            </b-link>
            <div class="order-item__text">
              {{ offer.order.text }}
            </div>
            <b-button
              variant="primary"
              :to="'/orders/' + offer.order._id"
              class="order-item__btn"
              >Детали заказа</b-button
            >
            <div class="order-item__created">
              Создан: {{ offer.order.createdAt | moment("DD.MM.YY H:mm") }}
            </div>
          </div>
        </div>
      </div>
      <div class="offer-detail">
        <p class="offer-detail__date">
          Создан: {{ offer.createdAt | moment("DD.MM.YY H:mm") }}

          <!-- <span v-if="offer.createdAt !== offer.updatedAt">
            , Изменен: {{ offer.updatedAt | moment("DD.MM.YY H:mm") }}
          </span> -->
        </p>
        <p class="offer-detail__reserved" v-if="offer.state == 'Reserved'">
          Забронировано!!! Клиенту сообщили ваш телефон и адрес. Ожидайте!!!
          <br />
          <span v-if="offer.reservedUserPhone">
            Номер телефона клиента: {{ offer.reservedUserPhone }}</span
          >
        </p>
        <b-form @submit.prevent="changeOffer" v-if="offerChanging">
          <div class="row">
            <div class="col-md-12 col-lg-4">
              <b-form-group label="Аудио">
                <audio
                  controls=""
                  :src="'https://vp.bw2api.ru/' + offer.voice['url']"
                  v-if="offer.voice"
                ></audio>
                <p v-else>Аудио нет</p>
              </b-form-group>
              <b-form-group label="Новое аудио">
                <MyRecorder @update="patchOffer.voice = $event" />
              </b-form-group>
            </div>
            <div class="col-md-6 col-lg-4">
              <b-form-group label="Текстовое примечание">
                <b-form-input
                  id="text"
                  v-model="patchOffer.text"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 col-lg-4">
              <b-form-group label="Цена" label-for="price">
                <b-form-input
                  id="price"
                  v-model="patchOffer.price"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <b-form-group label="Фото детали">
            <div class="row">
              <div class="col-auto" v-for="n in 3" :key="n">
                <label
                  class="btn-file"
                  v-bind:class="{
                    added:
                      patchOffer['photo' + n]['file'] ||
                      patchOffer['photo' + n]['url'],
                  }"
                  :for="'fileField_' + n"
                >
                  <div
                    v-if="patchOffer['photo' + n]['url']"
                    class="btn-file__preview"
                    :style="
                      'background-image: url(' +
                      'https://vp.bw2api.ru/' +
                      patchOffer['photo' + n]['url'] +
                      ');'
                    "
                  ></div>
                  <div
                    v-else
                    class="btn-file__preview"
                    :style="
                      'background-image: url(' +
                      patchOffer['photo' + n]['blob'] +
                      ');'
                    "
                  ></div>
                  <div class="btn-file__reset"></div>

                  <div class="btn-file__btns">
                    <b-button-group class="m-2" size="sm">
                      <b-button
                        title="Удалить"
                        variant="danger"
                        @click.prevent="patchOffer['photo' + n] = {}"
                      >
                        <b-icon icon="x"></b-icon>
                      </b-button>
                    </b-button-group>
                  </div>

                  <input
                    type="file"
                    :id="'fileField_' + n"
                    v-on:change="onFileChange($event, 'photo' + n)"
                  />
                </label>
              </div>
            </div>
          </b-form-group>
          <b-button
            type="button"
            variant="danger"
            class="mr-2"
            @click="(offerChanging = false), loadOffer()"
          >
            Отменить
          </b-button>
          <b-button type="submit" variant="success" class="mr-2">
            Сохранить
          </b-button>
        </b-form>
        <div v-else>
          <div class="row">
            <div class="col-md-12 col-lg-4">
              <div class="mb-3">
                <b-form-group label="Аудио">
                  <audio
                    controls=""
                    :src="'https://vp.bw2api.ru/' + offer.voice['url']"
                    v-if="offer.voice"
                  ></audio>

                  <p v-else>Аудио нет</p>
                </b-form-group>
                <b-form-group
                  v-if="offer.voice"
                  label="Текст из аудио"
                  label-for="voiceText"
                >
                  <b-form-input
                    id="voiceText"
                    :value="offer.voiceText"
                    type="text"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <b-form-group label="Текстовое примечание">
                <b-form-input
                  id="text"
                  v-model="offer.text"
                  type="text"
                  readonly
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 col-lg-4">
              <b-form-group label="Цена" label-for="price">
                <b-form-input
                  id="price"
                  v-model="offer.price"
                  type="text"
                  readonly
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <p>Фото детали</p>
          <div class="row">
            <div class="col-auto" v-for="n in 3" :key="n">
              <div class="gallery-item" v-if="offer['photo' + n]">
                <img
                  :src="'https://vp.bw2api.ru/' + offer['photo' + n]['url']"
                  class="mb-3"
                  data-fancybox="gallery"
                />
              </div>
            </div>
          </div>

          <b-button
            type="button"
            variant="primary"
            class="mr-2"
            @click="offerChanging = !offerChanging"
          >
            Редактировать
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Fancybox } from "@fancyapps/ui";
import MyRecorder from "../components/MyRecorder";

import axios from "axios";

export default {
  name: "offer-details",
  data() {
    return {
      offer: null,
      savedOffer: null,
      patchOffer: {
        text: "",
        photo1: {},
        photo2: {},
        photo3: {},
        price: "",
        voice: {},
      },

      formDisable: false,
      offerChanging: false,
    };
  },
  computed: {},
  components: { MyRecorder },
  async mounted() {
    this.loadOffer();

    Fancybox.bind("[data-fancybox='gallery']", {});
  },
  methods: {
    ...mapActions(["fetchOffer"]),

    async loadOffer() {
      this.offer = await this.fetchOffer(this.$route.params["id"]);
    },

    onFileChange(e, name) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.patchOffer[name] = {
        file: files[0],
        blob: window.URL.createObjectURL(files[0]),
      };
    },

    async changeOffer() {
      this.formDisable = true;
      let formData = new FormData();

      formData.append("order", this.offer["order"]["_id"]);
      formData.append("_id", this.offer["_id"]);
      formData.append("creator", this.offer["creator"]);

      if (this.patchOffer["price"] != this.offer["price"]) {
        formData.append("price", this.patchOffer["price"]);
      }
      if (this.patchOffer["text"] != this.offer["text"]) {
        formData.append("text", this.patchOffer["text"]);
      }

      if (this.patchOffer["voice"]["blob"]) {
        formData.append("voice", this.patchOffer["voice"]["blob"], "audio.mp3");
      }

      if (this.patchOffer["photo1"]["file"]) {
        formData.append("photo1", this.patchOffer["photo1"]["file"] || null);
      }

      if (this.patchOffer["photo2"]["file"]) {
        formData.append("photo2", this.patchOffer["photo2"]["file"] || null);
      }

      if (this.patchOffer["photo3"]["file"]) {
        formData.append("photo3", this.patchOffer["photo3"]["file"] || null);
      }

      let response = await axios.patch(
        "https://vp.bw2api.ru/api/v1/crud/offer",
        formData
      );

      if (response.status == 200) {
        this.$bvToast.toast(`Ваш ответ успешно изменен`, {
          title: "Изменено",
          autoHideDelay: 5000,
          variant: "success",
        });

        this.formDisable = false;
        this.offerChanging = false;
        this.loadOffer();
      } else {
        this.$bvToast.toast(`Что-то пошло не так. Попробуйте ещё раз`, {
          title: "Ошибка",
          autoHideDelay: 5000,
          variant: "danger",
        });
        this.formDisable = false;
      }
    },
  },

  watch: {
    offer: function () {
      this.patchOffer["text"] = this.offer["text"] || "";
      this.patchOffer["voice"] = _.clone(this.offer["voice"]) || {};
      this.patchOffer["photo1"] = _.clone(this.offer["photo1"]) || {};
      this.patchOffer["photo2"] = _.clone(this.offer["photo2"]) || {};
      this.patchOffer["photo3"] = _.clone(this.offer["photo3"]) || {};
      this.patchOffer["price"] = this.offer["price"] || {};
    },
  },
};
</script>
